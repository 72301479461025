.case-study {
  font-family: 'Outfit';
  font-weight: 300;
  text-align: left;
}

.case-study p {
  font-size: 16px;
  line-height: 1.3;
}

h2 {
  font-weight: 500;
}

h3 {
  font-weight: normal;
  font-size: 16px;
}

.banner {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60vh;
  color: white;
}

.banner::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0));
  z-index: 1; /* Ensure the shadow is above the image but below the content */
}

.banner-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.banner-content {
  text-align: center;
  z-index: 2; /* Ensure the content is above the shadow */
}

.banner-content h1 {
  margin-top: 100px;
  color: #f4f4f4;
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.banner-content p {
  font-size: 1.25rem;
}

.information {
  display: flex;
  flex-direction: row;
  padding-top: 1rem;
  padding-left: 6rem;
  padding-bottom: 1rem;
  background-color: #0C5753;
  color: #dadada;
  justify-content: space-around;
}

.info-section {
  flex: 1;
  text-align: left;
}

.info-section p {
  font-size: 1rem;
  margin-bottom: 0.5rem;
}

.overview {
  margin-top: 2rem;
  margin-left: 8rem;
  margin-right: 8rem;
  margin-bottom: 4rem; 
}

.final-product-teaser {
  margin-left: 8rem;
  margin-right: 8rem;
  margin-bottom: 4rem; 
}

.demo-videos-list {
  align-items: center;
  width: 100%;
}

.demo-section {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  width: 80%;
  margin: 0 auto;
}

.video-container {
  position: relative;
  width: 300px;
  height: auto;
  margin: 0;
}

.case-study-video {
  width: 100%;
  height: auto;
  border-radius: 20px 20px 25px 25px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.91); /* adjust to scale vid size */
}

.case-study-video-frame {
  position: relative;
  width: 100%;
  height: auto;
}

.case-study-image {
  max-width: 100%;
  height: auto;    
  display: block;  
  margin: 0 auto; 
}

.video-description {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  padding: 1rem;
}

.partner-requests {
  margin-left: 8rem;
  margin-right: 8rem;
  margin-bottom: 4rem; 
}

.user-flow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap; /* allows wrapping on smaller screens */
  gap: 20px;
  margin-top: 1rem;
  margin-bottom: 4rem;
}

.flow-step {
  /* background-color: #d0eae5; */
  padding: 1.4rem 1rem;
  border-width: 2px;
  border-style: solid; 
  border-radius: 10px;
  border-color:#6bb0a3;
  text-align: center;
  font-weight: 500;
  position: relative;
  flex: 1; /* Allows boxes to grow/shrink as needed */
  min-width: 120px; /* Ensure minimum size */
}

.flow-step:not(:last-child)::after {
  content: '→';
  position: absolute;
  right: -24px; /* Adjust as needed for spacing */
  top: 50%;
  transform: translateY(-50%);
  font-size: 1.5rem;
  color: #141414; /* Arrow color */
}

.problem-statement {
  background-color: #dcedea;
  border-radius: 15px;
  padding: 1rem;
  margin-left: 8rem;
  margin-right: 8rem;
  margin-bottom: 4rem; /* Added margin-bottom */
}

.industry-research {
  margin-left: 8rem;
  margin-right: 8rem;
  margin-bottom: 4rem; 
}

.user-research {
  margin-left: 8rem;
  margin-right: 8rem;
  margin-bottom: 4rem; 
}

.bubble-container {
  display: flex;
  flex-direction: column;
  align-items: center; 
  gap: 1rem; 
  margin: 0 auto; 
  max-width: 80%; 
  font-style: italic;
}

.bubble {
  background-color: #bfe2ea; 
  border-radius: 15px;
  padding: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 100%; 
  font-size: 26px;
}


.data-analysis {
  margin-left: 8rem;
  margin-right: 8rem;
  margin-bottom: 4rem; 
}

.empathy-maps {
  max-height: 600px;
}

.ideation {
  margin-left: 8rem;
  margin-right: 8rem;
  margin-bottom: 4rem; /* Added margin-bottom */
}

.ideation img {
  max-width: 80%;
}

.design-process {
  margin-left: 8rem;
  margin-right: 8rem;
  margin-bottom: 4rem;
}

.design-process img {
  max-width: 80%; 
}

.image-text-container {
  display: grid;
  grid-template-columns: 25% 75%; 
  align-items: start;  
  margin-bottom: 2rem; 
}

.image-text-container img {
  max-width: 100%; /* Ensures images fit within their grid cell */
  border-radius: 15px;
  background-color: #dcedea;
  padding: 1rem;
}

.image-text-container p {
  margin: 0 1rem 0 0; /* Adds space between text and image */
}

.text-container {
  display: flex;
  flex-direction: column;
}



.prototyping {
  margin-left: 8rem;
  margin-right: 8rem;
  margin-bottom: 4rem;
}

.hifi-grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr; /* Three equal-width columns */
  gap: 2rem; /* Space between grid items */
}

.hifi-item {
  text-align: center; /* Center the text below the image */
}

.hifi-item img {
  max-width: 60%; /* Ensure the image fits within its container */
  height: auto; /* Maintain the image's aspect ratio */
}

.hifi-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
}

.hifi-container img {
  max-width: 75%;
  height: auto;
}


.graphics {
  margin-left: 8rem;
  margin-right: 8rem;
  margin-bottom: 4rem;
}

.graphics img {
  max-width: 100%; 
  height: auto; 
  display: block; /* Removes any inline spacing */
  margin: 1rem auto; /* Centers the images horizontally and adds margin around them */
  border-radius: 15px; 
}


.inspiration-images {
  display: flex;
  gap: 1rem; /* Space between images */
  justify-content: center;
  flex-wrap: wrap; /* Allows wrapping to the next line if needed */
}

.inspiration-images img {
  height: 300px; /* Adjust as needed for larger images */
  width: auto; /* Maintain aspect ratio */
  max-width: calc(50% - 0.5rem); /* Ensure images fit within their container with some margin */
  border-radius: 15px;
  object-fit: cover; /* Ensure images cover the container while maintaining aspect ratio */
}

/* Optional: Media queries for better responsiveness */
@media (max-width: 768px) {
  .inspiration-images img {
    height: 200px; /* Adjust for smaller screens */
    max-width: calc(100% - 0.5rem); /* Full width on smaller screens with margin */
  }
}

.video-row {
  display: flex;
  gap: 1rem;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 1rem;

}

.video-item {
  flex: 1 1 calc(25% - 1rem); /* Four items in a row, adjusting for gaps */
  max-width: calc(25% - 1rem);
  position: relative;
}

.case-study-video-2 {
  width: 100%;
  height: auto;
  border-radius: 25px;
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.3); /* Drop shadow */
  display: block; /* Ensure it’s a block element to avoid inline issues */
}

.video-description-2 {
  text-align: center;
  margin-top: 1rem; /* Space between video and description */
}

/* Optional: Media queries for better responsiveness */
@media (max-width: 768px) {
  .video-row {
    flex-direction: column;
  }

  .video-item {
    flex: 1 1 100%;
    max-width: 100%;
  }
}

.final-product {
  margin-left: 8rem;
  margin-right: 8rem;
  margin-bottom: 4rem;
}

.final-product img {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
  border-radius: 15px;
}
.figma-embed-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}


.takeaways {
  margin-left: 8rem;
  margin-right: 8rem;
  margin-bottom: 4rem;
}