.progress-bar-container {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 10px; /* Height of the progress bar */
    background-color: #f0f0f0; /* Background color of the progress bar container */
    z-index: 1000;
  }
  
  .progress-bar {
    height: 100%;
    background-color: #0f4884; /* Color of the progress bar */
    transition: width 0.2s ease;
  }
  