.about {
    display: flex;
    align-items: top;
    margin: 8rem;
}
  
  .about-image {
    max-width: 25%;
    margin-right: 3rem;
    border-radius: 15px;
}
  
  .about-text {
    font-family: 'Outfit';
    font-weight: 300;
    text-align: left;
    flex: 1; /* This makes sure the text takes up the remaining space */
  }