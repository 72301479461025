.play {
    margin: 8rem;
    text-align: left;
  }
  
  .image-grid {
    display: flex; 
    flex-wrap: wrap;
    margin-top: 20px;
    }
  
  .grid-item {
    width: 250px;
    padding: 10px;
    background-color: #e0e0e0;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px; /* Space between grid items */
    margin-right: 20px; /* Space to the right of each grid item */
  }
  
  .grid-item img {
    width: 100%;
    height: auto;
    display: block;
    border-radius: 8px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }

  .grid-item img:hover {
    transform: scale(1.03); /* Slightly enlarge the image */
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2); /* Add a shadow */
  }


  /* Modal CSS */
.modal {
    display: none; /* Hidden by default */
    position: fixed;
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto; /* Enable scroll if needed */
    background-color: rgba(0,0,0,0.8); /* Black background with opacity */
  }
  
  .modal-content {
    margin: auto;
    display: block;
    width: 80%;
    max-width: 700px;
  }
  
  .close {
    position: absolute;
    top: 15px;
    right: 35px;
    color: #f1f1f1;
    font-size: 40px;
    font-weight: bold;
    cursor: pointer;
  }
  
  .close:hover,
  .close:focus {
    color: #bbb;
    text-decoration: none;
    cursor: pointer;
  }
  
  #caption {
    margin: 15px;
    color: #f1f1f1;
    text-align: center;
  }
