.case-study {
    font-family: 'Outfit';
    font-weight: 300;
    text-align: left;
  }
  
  .case-study p {
    font-size: 16px;
    line-height: 1.3;
  }
  
  h2 {
    font-weight: 500;
  }
  
  h3 {
    font-weight: normal;
    font-size: 16px;
  }
  
  .dineable-banner {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60vh;
    color: white;
  }
  
  .dineable-banner::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0));
    z-index: 1; /* Ensure the shadow is above the image but below the content */
  }
  
  .dineable-banner-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
  }
  
  .dineable-banner-content {
    text-align: center;
    z-index: 2; /* Ensure the content is above the shadow */
  }
  
  .dineable-banner-content h1 {
    margin-top: 100px;
    color: #f4f4f4;
    font-size: 2.5rem;
    margin-bottom: 1rem;
  }
  
  .dineable-banner-content p {
    font-size: 1.25rem;
  }
  
  .dineable-information {
    display: flex;
    flex-direction: row;
    padding-top: 1rem;
    padding-left: 6rem;
    padding-bottom: 1rem;
    background-color: #b57352;
    color: #dcdcdc;
    justify-content: space-around;
  }
  
  .info-section {
    flex: 1;
    text-align: left;
  }
  
  .info-section p {
    font-size: 1rem;
    margin-bottom: 0.5rem;
  }

  .problem-statement {
    background-color: #f6d4af;
    border-radius: 15px;
    padding: 1rem;
    margin-left: 8rem;
    margin-right: 8rem;
    margin-bottom: 4rem;
  }