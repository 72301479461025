.footer {
  position: relative;
  bottom: 0;
  width: 100%;
  background-color: #f4f4f4;
  color: #333;
  text-align: left;
  padding: 20px 0;
  border-top: 1px solid #ddd;
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.fancy-text {
  font-weight: bold;
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.footer a {
  color: #007bff;
  text-decoration: none;
  margin: 0 5px;
}

.footer a:hover {
  text-decoration: underline;
}

.updated {
  margin-top: 30px;
  font-style: italic;
  color: #555;
}