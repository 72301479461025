.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  /* background-color: #fff; */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  transition: top 0.3s;
}

.navbar-hidden {
  top: -100px; 
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px; 
}

.logo {
  height: 40px; 
}

.navbar-menu {
  list-style: none;
  display: flex;
  gap: 40px;
  margin: 0;
  padding: 0;
}

.navbar-menu li a {
  text-decoration: none;
  color: #2d2d2d; 
  font-weight: bold;
}

.navbar-menu a:hover {
  /* color: #b9deff; Change text color on hover */
  border: 2px solid #ffffff; /* Border color and width */
  border-radius: 10px; 
  padding: 5px 10px; 
}
