html {
  scroll-behavior: smooth; 
}

.splash-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  background-color: #a4bee5;
  color: #1d2f5a;
  animation: fadeIn 1.5s ease-in-out;
}

.splash-screen h1 {
  font-size: 3rem;
  font-weight: '200';
  margin-bottom: 1rem;
}

.splash-screen p {
  font-size: 1.5rem;
}

.see-my-work-button {
  display: inline-block;
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #2964b1; 
  color: #f0f0f0; 
  text-decoration: none;
  font-weight: bold;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.see-my-work-button:hover {
  background-color: #5a8adf;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.projects-section {
  padding: 50px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
    align-items: center;

}

.project-list {
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 100%;
  align-items: center;

}

.project-item {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  align-items: start;
  width: 100%;
  max-width: 80%;
}

.project-item .description {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  text-align: left;
}

.project-item h3 {
  font-size: 1.5em;
}

.project-item p {
  margin: 5px 0;
}

.project-item a {
  color: #007bff;
  text-decoration: none;
}

.project-item a:hover {
  text-decoration: underline;
}

.project-thumbnail {
  width: 100%; 
  height: auto;
  max-width: 1000px;
  border: 1px solid #ddd;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  justify-self: end; 
  transition: transform 0.2s ease;

}

.project-item img:hover {
  transform: scale(1.05); 
}

.story-link {
  display: flex;
  align-items: center;
}

.story-link p {
  margin-right: 5px;
}

.click-text {
  font-style: italic;
}

.arrow-icon {
  width: 50px; 
  height: auto;
  margin-left: 5px;
}

.tags {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
}

.tag {
  /* background-color: #e0e0e0; */
  font-size: 12px;
  color: #333;
  padding: 5px 10px;
  border-radius: 5px;
  border-width: 1px;
  border-style: solid;
  border-color:#353535;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.tag:hover {
  background-color: #d0d0d0;
}
